export const animationOne = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: -100,
  },
};

export const animationTwo = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: 100,
  },
};

export const animationThree = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
   y: 100,
  },
};

export const transition = {
  duration: 0.3,
};
