import {
  FaHtml5,
  FaCss3Alt,
  FaNodeJs,
  FaNode,
  FaReact,
  FaWordpress,
  FaPhp,
} from "react-icons/fa";
import { DiPhotoshop, DiIllustrator } from "react-icons/di";
export const SkillsProgress = [
  { skillsIcon: <FaHtml5 />, title: "HTML", percentage: 95 },
  { skillsIcon: <FaCss3Alt />, title: "CSS", percentage: 95 },
  { skillsIcon: <FaNodeJs />, title: "JavaScript", percentage: 70 },
  { skillsIcon: <FaNode />, title: "Node Js", percentage: 30 },
  { skillsIcon: <FaReact />, title: "React Js", percentage: 45 },
  { skillsIcon: <FaWordpress />, title: "Wordpress", percentage: 90 },
  { skillsIcon: <FaPhp />, title: "PHP", percentage: 50 },
  { skillsIcon: <DiPhotoshop />, title: "Photoshop", percentage: 90 },
  {
    skillsIcon: <DiIllustrator />,
    title: "Illustrator",
    percentage: 80,
  },
];
