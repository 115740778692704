import css from "../HomeProjectsCss.module.css";
import img1 from '../images/Projects_One.jpg';
import img2 from '../images/Projects_Two.jpg';
import img3 from '../images/Projects_Three.jpg';
import img4 from '../images/Projects_Four.jpg';
export const HomeProjectData = [
    
    { id: 1, css: `${css.cell} ${css.cellOne}`, img:img1, alt: 'Projects_One' },
    { id: 2, css: `${css.cell} ${css.cellTwo}`, img:img2, alt: 'Projects_Two' },
    { id: 3, css: `${css.cell} ${css.cellThree}`, img:img3, alt: 'Projects_Three' },
    { id: 4, css: `${css.cell} ${css.cellFour}`, img:img4, alt: 'Projects_Four' },

];
