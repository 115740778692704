import React from "react";
import { SwitchToggel, Notch } from "./SwitchBtn.elements";

export const SwitchBtn = ({ theme, toggleTheme }) => {
  return (
    <>
      <SwitchToggel>
        <Notch onClick={toggleTheme} />
      </SwitchToggel>
    </>
  );
};
