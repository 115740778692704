import { motion } from "framer-motion";
import React from "react";
import CommonAbout from "../../components/About/CommonAbout";
import CommonProfile from "../../components/CommonProfile/CommonProfile";
import Social from "../../components/Social/Social";
import SainiImg from "../../images/Shubham-Saini.png";
import { animationTwo, transition } from "../Animation";

const Profile = () => {
  return (
    <>
      <motion.div initial="out" animate="in"  exit="out" variants={animationTwo} transition={transition}>
        <div className="container">
          <CommonAbout
            aboutImg={SainiImg}
            social={<Social />}
            tagLine="This is Me"
            aboutPara="I am Shubham, a web developer with a good experience and I am honest towards my work"
          />
          <CommonProfile />
        </div>
      </motion.div>
    </>
  );
};

export default Profile;
