import styled from "styled-components";

export const ErrorSection = styled.div`
background-color:var(--First_Color);
position:fixed;
top:0;
width:100%;
height:100vh;
left:0;
z-index:6666;
display:flex;
justify-content:center;
align-items:center;
padding:0rem 2rem;
`
export const ErrorBox = styled.div`
background-color:var(--Second_Color);
box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
width:80rem;
    /* height:60vh; */
border-radius:5rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
position: relative;
text-align:center;
padding:4rem 2rem;
  &::before {
    content: "";
    background-image: linear-gradient(
      to top,
      var(--Bg_Color_Three) 50%,
      var(--Third_Color) 50%
    );
    width: 60%;
    height: 5rem;
    margin: auto;
    right: 0rem;
    display: inline-block;
    top: -2.5rem;
    border-radius: 50rem;
    left: 0rem;
    position: absolute;
  }
  &::after {
    content: "";
    background-image: linear-gradient(
      to bottom,
      var(--Bg_Color_Three) 50%,
      var(--Third_Color) 50%
    );
    width: 60%;
    height: 5rem;
    margin: auto;
    right: 0rem;
    display: inline-block;
    bottom: -2.5rem;
    border-radius: 50rem;
    left: 0rem;
    position: absolute;
  }
`
export const ErrorTitle = styled.div`
/* 
font-size:14rem;
font-weight:100;
box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); */
/* color:var(--First_Color); */
& img{
  max-width: 36rem;
  width: 100%;
}
`
export const ErrorPara = styled.p`
margin:5rem 0rem;
`