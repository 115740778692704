import { motion } from "framer-motion";
import styled from "styled-components";

export const ScrollTabSection = styled(motion.section)`
  width: 5.8rem;
  height: 30rem;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: -20%;
  transform: translate(0%, -50%);
  transition: 0.3s;
  z-index: 999;
  &:hover {
    width: 30rem;
    border-radius: 0rem 2rem 2rem 0rem;
    transition: 0.3s;
  }
  &.ScrollTabActive {
    left: 0%;
  }
  @media screen and (max-width: 1024px) {
    &.ScrollTabActive{
      display:none;
    }
  }
`;
export const ScrollTabBox = styled.div`
  width: 30rem;
  height: 30rem;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
`;
export const ScrollTabTitle = styled.h4`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3.2rem;
  background-color: var(--Second_Color);
  transition: 0.3s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 0rem 3rem 0rem 1.2rem;
  text-align: center;
  width: fit-content;
  border-radius: 100rem 100rem 100rem 100rem;
  margin: 1.6rem 0rem 1.6rem 4rem;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: var(--Third_Color);
  }
  
`;
export const ScrollTabIcon = styled.i`
  color: var(--H_Color);
  position: relative;
  top: 0.4rem;
  transition: 0.3s;
  font-size: 1.6rem;
  left: -5.2rem;
  ${ScrollTabTitle}:hover & {
    color: var(--Third_Color);
  }
  ${ScrollTabTitle}:active & {
    color: var(--Third_Color);
  }

`;
