import styled from "styled-components";
import moon from "../../images/Moon.svg";
import sun from "../../images/Sun.svg";

export const SwitchToggel = styled.div`
  width: 5rem;
  height: 5rem;
  background-color: white;
  border-radius: 50rem;
  cursor: pointer;
  margin-left: 3rem;
  position: relative;
  transition: 0.2s ease;
  overflow: hidden;
`;
export const Notch = styled.div`
  &::before,
  &::after {
    content: "";
    position: absolute;

    width: 4rem;
    height: 4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50rem;
    background-color: var(--Second_Color);
    transition: 0.2s ease;
  }
  &::before {
    background: url("${sun}") center center no-repeat;
    background-color: var(--Third_Color);
    transform: ${({ theme }) => theme.transform};
  }
  &::after {
    background: url("${moon}") center center no-repeat;
    transform: ${({ theme }) => theme.transformd};
    background-color: var(--Second_Color);
  }
`;
