import React from "react";
import { Titles, Paras, TSpan } from "./Heading.elements";
const Heading = (props) => {
  return (
    <>
      <Titles id={props.id}>
        
        {props.hTitleOne}
        <TSpan>{props.hSpan}</TSpan>
        {props.hTitleTwo}
      </Titles>
      <Paras>{props.hPara}</Paras>
    </>
  );
};

export default Heading;
