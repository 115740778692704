import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaGithub,
} from "react-icons/fa";

export const SocialLinks = [
  { icon: <FaFacebookF />, link: "https://www.facebook.com/saini.sardar.73" },
  { icon: <FaLinkedinIn />, link: "https://www.linkedin.com/in/shubham-saini-3390191b8" },
  { icon: <FaInstagram />, link: "https://www.instagram.com/sainisardar18" },
  { icon: <FaGithub />, link: "https://github.com/shubhamsaini1937" },
];
