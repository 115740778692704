import React from "react";
import SainiImg from "../../images/Shubham-Saini.png";
import { Button } from "../Button/Button";
import Heading from "../Heading/Heading";

import CommonAbout from "./CommonAbout";
const About = () => {
  return (
    <>
      <div className="container"  id="about">
        <Heading
          hTitleOne="About"
          hSpan=" Myself"
          hTitleTwo=""
          hPara="“I am eager to learn. and I like to keep a positive attitude.”"
        />
        <CommonAbout
          aboutImg={SainiImg}
          tagLine="This is Me"
          aboutPara="I started my career in the IT field in 2018, I love this work and I am 
        very honest about my work, I have a lot to learn in this field right now and I keep learning."
          btn={
            <Button visit="/profile" buttonStyle="btn--big">
              View Profile
            </Button>
          }
          
        />
      </div>
    </>
  );
};

export default About;
