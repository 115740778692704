import React from "react";
import { SkillsProgress } from "../../data/SkillsProgress";
import Heading from "../Heading/Heading";
import {
  SkillsSection,
  SkillsRow,
  SkillsCol,
  SkillsIconBox,
  SkillsIcon,
  SkillsTitle,
  SkillsBox,
  SkillsSvg,
  SkillsPercentage,
} from "./Skills.elements";
const Skills = () => {
  return (
    <>
      <div className="container" id="skills">
        <Heading
          hTitleOne="My "
          hSpan="Skills"
          hTitleTwo=""
          hPara="What can I do"
        />
        <SkillsSection>
          <SkillsRow>
            {SkillsProgress.map((skillsData, index) => (
              <SkillsCol key={index}>
                <SkillsIconBox className="SkillIconBoxHover">
                  <SkillsIcon>{skillsData.skillsIcon}</SkillsIcon>
                </SkillsIconBox>
                <SkillsTitle>{skillsData.title}</SkillsTitle>
                <SkillsBox>
                  <SkillsSvg viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="40" />
                    <circle cx="50" cy="50" r="40" />
                  </SkillsSvg>
                  <SkillsPercentage>{skillsData.percentage}%</SkillsPercentage>
                </SkillsBox>
              </SkillsCol>
            ))}
          </SkillsRow>
        </SkillsSection>
      </div>
    </>
  );
};

export default Skills;
