import styled, { createGlobalStyle } from "styled-components";

export const lightTheme = {
  First_Color: "white",
  Second_Color: "#f4f6f7",
  H_Text_Color: "#23263A",
  P_Text_Color: "#23263A",
  Btn_Primary_Color: "#23263A",
  Bg_Color_One: "#f4f6f7",
  Bg_Color_Two: "#23263A",
  Bg_Color_Three: "#f4f6f7",
  // toggle transform
  transformd: "translate(-180%, -50%) ",
};

export const darkTheme = {
  First_Color: "#1F2235",
  Second_Color: "#23263A",
  H_Text_Color: "white",
  P_Text_Color: "#8b949e",
  Btn_Primary_Color: "white",
  Bg_Color_One: "#23263a8c",
  Bg_Color_Two: "#FF4A57",
  Bg_Color_Three: "#23263A",
  // toggle transform
  transform: "translate(80%, -50%)",
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

export const Col = styled.div`
  flex: 1 1 50rem;
`;

export const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
:root{
    --First_Color: ${({ theme }) => theme.First_Color};
    --Second_Color: ${({ theme }) => theme.Second_Color};
    --Third_Color: #FF4A57;
    --H_Color: ${({ theme }) => theme.H_Text_Color};
    --P_Color: ${({ theme }) => theme.P_Text_Color};
    --Btn_Primary_Color: ${({ theme }) => theme.Btn_Primary_Color};
    --Bg_Color_One:${({ theme }) => theme.Bg_Color_One};
    --Bg_Color_Two:${({ theme }) => theme.Bg_Color_Two};
    --Bg_Color_Three:${({ theme }) => theme.Bg_Color_Three};
    --H_Font: 'Poppins', sans-serif;
}
html,body{
    font-size:62.5%;
    background-color: var(--First_Color);
    scroll-behavior:smooth;
}
body{
  overflow-x:hidden;

}
body::-webkit-scrollbar-track
{
	
	background-color: var(--First_Color);
  border-radius:100rem;

}

body::-webkit-scrollbar
{
	width: 8px;
  border-radius:100rem;


}

body::-webkit-scrollbar-thumb
{
	background-color: var(--Third_Color);
  border-radius:100rem;
}
body::-webkit-scrollbar-thumb:active
{
	background-color: var(--H_Color);
}
.container-fluid{
    width:100%;
    max-height:100vh;
    height:auto;
    background-color:var(--Second_Color);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    z-index:999;
    
}
.container{
    width:120rem;
    margin: auto;
    height:auto;
    z-index:999;
    padding:1.8rem 1rem;
    
}

section{
  padding: 3rem 0rem 10rem 0rem;

}
.active h4 {
    background-color: var(--Third_Color);
    color:white;
  }
  .active i {
    color: var(--Third_Color);
  }


h1,h2,h3,h4,h5,h6,span{
    font-family: var(--H_Font);
    letter-spacing:.3rem;
    color: var(--H_Color);
    font-size:3.2rem;
    line-height:5rem;
    font-weight:500
}

p,a,ul,li, input{
    font-family: var(--H_Font);
    color: var(--P_Color);
    letter-spacing:.3rem;
    font-size:1.6rem;
    line-height:3rem;
    font-weight:400;

    
}
a, input{
  color:var(--H_Color);
  text-decoration: none

}


ul,li{
    list-style: none
}



@media  screen and (max-width: 1200px) {
  html,body {
    font-size: 55%;
  }
  .container{
    width:100%;
    padding:1.8rem 2.2rem;
    
}
}

@media  screen and (max-width: 892px) {
  html,body {
    font-size: 50%;
  }
  p,a,ul,li{

    font-size:1.8rem;
    

    
}
}



`;
