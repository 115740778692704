import styled from "styled-components";

export const SkillsSection = styled.section`
  padding: 8rem 0rem 14rem 0rem;

  @media screen and (max-width: 768px) {
    padding: 6rem 0rem 12rem 0rem;
  }
`;
export const SkillsRow = styled.div`
  background-color: var(--Bg_Color_Three);
  border-radius: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15rem 6rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  position: relative;
  &::before {
    content: "";
    background-image: linear-gradient(
      to top,
      var(--Bg_Color_Three) 50%,
      var(--Third_Color) 50%
    );
    width: 60%;
    height: 5rem;
    margin: auto;
    right: 0rem;
    display: inline-block;
    top: -2.5rem;
    border-radius: 50rem;
    left: 0rem;
    position: absolute;
  }
  &::after {
    content: "";
    background-image: linear-gradient(
      to bottom,
      var(--Bg_Color_Three) 50%,
      var(--Third_Color) 50%
    );
    width: 60%;
    height: 5rem;
    margin: auto;
    right: 0rem;
    display: inline-block;
    bottom: -2.5rem;
    border-radius: 50rem;
    left: 0rem;
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 6rem 0rem;
  }
`;
export const SkillsCol = styled.div`
  flex: 1 1 26rem;
  background-color: #1f2235;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background-image: linear-gradient(
    to right,
    var(--First_Color) 50%,
    var(--Bg_Color_One) 50%
  );
  border-radius: 5rem;
  margin: 5rem 5rem 15rem 5rem;
  cursor: pointer;
  color: var(--H_Color);

  text-align: center;
  &:nth-child(1) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * ${95}) / 100);
  }
  &:nth-child(2) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 95) / 100);
  }
  &:nth-child(3) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 70) / 100);
  }
  &:nth-child(4) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 30) / 100);
  }
  &:nth-child(5) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 45) / 100);
  }
  &:nth-child(6) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 90) / 100);
  }
  &:nth-child(7) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 50) / 100);
  }
  &:nth-child(8) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 90) / 100);
  }
  &:nth-child(9) circle:nth-child(2) {
    stroke-dashoffset: calc(251 - (251 * 80) / 100);
  }
  &:nth-child(9) {
    margin-bottom: 5rem;
  }
  transition: 0.3s;

  &:hover {
    transform: translateY(-10px);
    transition: 0.3s;
  }
  &:hover .SkillIconBoxHover {
    background-color: var(--Third_Color);
    transition: 0.3s;
    color: white;
  }
  @media screen and (max-width: 768px) {
    margin: 5rem 3rem 15rem 3rem;

    
    
  }
`;
export const SkillsIconBox = styled.div`
  background-color: var(--Second_Color);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  width: fit-content;
  padding: 2.4rem;
  border: 0.8rem solid var(--First_Color);
  border-radius: 50rem;
  transition: 0.3s;
  top: -5rem;
  position: relative;
  margin: auto;
`;
export const SkillsIcon = styled.i`
  font-size: 4rem;
  line-height: 0rem;
`;
export const SkillsTitle = styled.h4`
  font-size: 2.2rem;
  font-weight: 600;
`;
export const SkillsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: -5.5rem;
`;
export const SkillsSvg = styled.svg`
  width: 11rem;
  height: 11rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background-color: var(--First_Color);
  border-radius: 50rem;

  & circle {
    width: 100%;
    height: 100%;
    object-fit: contain;
    stroke: var(--Second_Color);
    fill: none;
    stroke-width: 6;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  & circle:nth-child(2) {
    stroke-dasharray: 251;
    stroke-dashoffset: 251;
    stroke: var(--Third_Color);
  }
`;
export const SkillsPercentage = styled.h5`
  position: absolute;
  font-size: 1.8rem;
  font-weight: 600;
`;
