import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container" style={{ textAlign: "center" }}>
          <p>© 2021 Shubham Saini. All Rights Reserved.</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
