import { motion } from "framer-motion";
import styled from "styled-components";

export const HeroSocialLink = styled.div`
  background-color: var(--Second_Color);
  margin-top: 3rem;
  width: fit-content;
  display: flex;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 50rem;
  padding: 1.8rem 2.8rem;
    z-index: 1;
  box-sizing: border-box;
  &::after {
    content: "";
    position: relative;
    width: 6.2rem;
    height: 0.3rem;
    background-color: var(--H_Color);
    border-radius: 50rem;
    display: inline-block;
    top: 2.5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 1.8rem 2rem;
    margin: 3rem auto auto auto;
    &::after {
      display: none;
    }
  }
`;
export const SocialBox = styled(motion.div)`
  border: 0.3rem solid var(--Third_Color);
  border-radius: 50rem;
  margin-right: 3rem;
  width: 4.8rem;
  height: 4.8rem;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  line-height: 5.5rem;

  &:hover {
    background-color: var(--Third_Color);

    transition: 0.2s;
  }
  &:hover a {
    color: white;
  }
  @media screen and (max-width: 768px) {
    margin: 0rem 1.4rem;
  }
`;
