import { FaCircle } from "react-icons/fa";

export const HomeScrollTabData = [
  {
    scrollIcon: <FaCircle />,
    scrollTitle: "About Myself",
    id: "about",
  },
  {
    scrollIcon: <FaCircle />,
    scrollTitle: "My Skills",
    id: "skills",
  },
  { scrollIcon: <FaCircle />, scrollTitle: "My Projects", id: "projects" },
  { scrollIcon: <FaCircle />, scrollTitle: "Back To Top", id: "backtotop" },
];
