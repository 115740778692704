import {
  AboutSection,
  AboutImg,
  AboutTagLine,
  AboutTitle,
  AboutPara,
} from "./Common.elements";

import React from "react";
import NameSvg from "./NameSvg";
import { Col, Row } from "../../globalStyle";

const CommonAbout = (props) => {
  return (
    <>
      <AboutSection>
        <Row className="aboutRow">
          <Col>
            <AboutImg
              src={props.aboutImg}
              drag
              dragConstraints={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
              dragElastic={0.5}
              whileTap={{ cursor: "grabbing" }}
            />
          </Col>
          <Col>
            <AboutTagLine>{props.tagLine}</AboutTagLine>
            <AboutTitle>
              <NameSvg />
            </AboutTitle>
            <AboutPara>{props.aboutPara}</AboutPara>
            {props.social}
            {props.btn}
          </Col>
        </Row>
      </AboutSection>
    </>
  );
};

export default CommonAbout;
