import { Button } from "../Button/Button";
import SainiImg from "../../images/Shubham-Saini.png";
import {
  HSection,
  HeroTagLine,
  HeroTitle,
  HeroPara,
  HeroBtn,
  HeroImgBox,
  HeroImg,
} from "./HeroSection.elements";
import Social from "../Social/Social";
import { Col, Row } from "../../globalStyle";

const HeroSection = () => {
  let currentDate = new Date();
  currentDate = currentDate.getHours();
  let greeting = "";
  if (currentDate >= 4 && currentDate < 12) {
    greeting = "Good Morning";
  } else if (currentDate >= 12 && currentDate < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }
  return (
    <>
      <div className="container" >
        <HSection>
          <Row>
            <Col>
              <HeroTagLine>
                Hello Guys, <span>{greeting}</span>
              </HeroTagLine>
              <HeroTitle>
                I am <span>Shubham Saini</span>
              </HeroTitle>
              <HeroPara>
                I am Shubham, a web developer with a good experience and I am
                honest towards my work
              </HeroPara>
              <HeroBtn>
                <Button visit="/projects">Projects</Button>
                <Button visit="/" buttonStyle="btn--primary">
                  Hire Me
                </Button>
              </HeroBtn>
            </Col>
            <Col className="HeroColImg">
              <HeroImgBox>
                <HeroImg
                  drag
                  dragConstraints={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                  dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                  dragElastic={0.5}
                  whileTap={{ cursor: "grabbing" }}
                  src={SainiImg}
                />
                <Social />
              </HeroImgBox>
            </Col>
          </Row>
        </HSection>
      </div>
    </>
  );
};

export default HeroSection;
