import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const DropContainer = styled.div`
position:fixed;
background-color:var(--Second_Color);
width:100%;
height:100%;
z-index:999;
transition: 0.3s;
opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
top:${({isOpen}) => (isOpen ? '0' : '-100%')};
left:0;
right:0;
bottom:0;
display:none;
@media screen and (max-width: 1024px) {
    
      display:block;
     
  }
`
export const DropBox = styled.div`

display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:100%;

`
export const DropLogo = styled.div`


    margin-bottom:4rem;
`
export const DropMenuLinks = styled(Link)`
  font-weight: 500;
  font-size:2rem;
  margin-bottom: 4rem;
  font-family: var(--H_Font);
  transition: 0.2s;
  &:hover {
    color: var(--Third_Color);
    transition: 0.2s;
  }
  &.activeNavMenu{
    color: var(--Third_Color);

  }

`