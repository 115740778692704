import React from "react";
import { Btn } from "./Button.elements";

const STYLES = ["btn--outline", "btn--primary", "btn--bg", "btn--big"];

export const Button = ({ children, buttonStyle,visit,target }) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  return (
    <>
      <Btn whileTap={{ scale: 0.8 }} to={visit} target={target} className={checkButtonStyle}>{children}</Btn>
    </>
  );
};
