import React from "react";
import { SocialLinks } from "../../data/HeroSectionSocialLink";
import { HeroSocialLink, SocialBox } from "./Social.elements";

const Social = () => {
  return (
    <>
      <HeroSocialLink>
        {SocialLinks.map((socialitem, index) => (
          <SocialBox whileTap={{ scale: 0.8 }} key={index}>
            <a href={socialitem.link} rel="noreferrer" target="_blank" >{socialitem.icon}</a>
          </SocialBox>
        ))}
      </HeroSocialLink>
    </>
  );
};

export default Social;
