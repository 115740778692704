import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MenuBarBtn{
      display:none;
    }
  @media screen and (max-width: 1024px) {
    .MenuBarBtn{
      display:block;
      z-index:1000;
      margin-left:2rem;
    }
  }
  
`;
export const NavLink = styled(Link)`
  line-height: 0rem;
`;
export const Logo = styled.img`
  width: 26rem;
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    
  
    
  }
`;
export const MenuItem = styled.div`
display:flex;
align-items:center;
  @media screen and (max-width: 1024px) {
 display:none;
    
  }
`;
export const NavMenuLinks = styled(Link)`
  font-weight: 500;
  margin-right: 3rem;
  font-family: var(--H_Font);
  transition: 0.2s;
  &:hover {
    color: var(--Third_Color);
    transition: 0.2s;
  }
  &.activeNavMenu{
    color: var(--Third_Color);

  }
`;
