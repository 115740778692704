import { motion } from "framer-motion";
import React from "react";
import Projects from "../../components/Projects/Projects";
import { animationThree, transition } from "../Animation";
const ProjectsPage = () => {
  return (
    <>
      <motion.div animate="in" initial="out" exit="out" variants={animationThree} transition={transition}>
        <Projects />
      </motion.div>
    </>
  );
};

export default ProjectsPage;
