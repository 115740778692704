import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

export const Btn = styled(motion(NavLink))`
  font-family: var(--H_Font);
  font-weight: 500;
  border: 0.3rem solid var(--Third_Color);
  border-radius: 100rem;
  color: white;
  padding: 0.8rem 3.2rem;
  transition: 0.3s;
  &.btn--primary {
    background-color: var(--Third_Color);

    &.btn--primary:hover {
      background-color: transparent;
      color: var(--Btn_Primary_Color);
    }
  }
  &.btn--big {
    padding: 1.2rem 3.2rem;
  }
  &.btn--outline,
  &.btn--big {
    color: var(--Btn_Primary_Color);
    &.btn--outline:hover,
    &.btn--big:hover {
      background-color: var(--Third_Color);
      color: white;
    }
  }

  &.btn--bg {
    color: var(--H_Color);
    &.btn--bg:hover {
      background-color: var(--Third_Color);
      color: white;
    }
  }
`;
