import { Switch, Route, useLocation } from "react-router-dom";
import { Home, Profile, ProjectsPage, ScrollToTop, Error404 } from "./pages";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import { AnimatePresence } from "framer-motion";

const App = () => {
  let location = useLocation();
  return (
    <>
      <Navbar />
      <ScrollToTop />

      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" component={Home} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/projects" component={ProjectsPage} />
          <Route component={Error404} />
        </Switch>
      </AnimatePresence>
      <Footer />
    </>
  );
};

export default App;
