import styled from "styled-components";
import { motion } from "framer-motion";
export const HSection = styled.section`

  @media screen and (max-width: 768px) {
    padding: 4rem 0rem;

  }
 .HeroColImg {
  display: flex;
   flex-direction: column;
   align-items: flex-end;
  }
`;

export const HeroTagLine = styled.h6`
  font-size: 1.6rem;
  background-color: var(--Second_Color);
  width: fit-content;
  padding: 0rem 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 50rem;
  margin-bottom: 4rem;

  span {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--Third_Color);
  }

  &::before {
    content: "";
    position: relative;
    width: 4rem;
    height: 0.3rem;
    background-color: var(--H_Color);
    border-radius: 50rem;
    display: inline-block;
    margin-right: 2rem;
    top: -0.36rem;
  }
  @media screen and (max-width: 768px) {
    margin: 4rem auto 4rem auto;
    padding: 0rem 2.4rem;

    &::before {
      display: none;
    }
  }
`;
export const HeroTitle = styled.h1`
  font-size: 3.8rem;
  span {
    font-size: 3.8rem;
    font-weight: 500;
    color: var(--Third_Color);
  }
  @media screen and (max-width: 768px) {
    font-size: 3.2rem;
    span {
    font-size: 3.2rem;
    
  }
  }
`;

export const HeroPara = styled.p`
  margin: 3.2rem 0rem 4.2rem 0rem;
  
`;
export const HeroBtn = styled.div`
  width: 34rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    margin: auto;
  width: 35rem;

  }
`;
export const HeroImgBox = styled.div`
  align-items: flex-end;
  text-align:right;
`;
export const HeroImg = styled(motion.img)`
  max-width: 43rem;
  width: 100%;
  position: relative;
  z-index:2;
  cursor: grab;
`;
