import styled from "styled-components";

export const Titles = styled.h3`
  text-align: center;
  margin-top:4rem;
  &::after {
    content: "";
    position: relative;
    width: 3rem;
    height: 0.3rem;
    border:0.3rem solid var(--Third_Color);
    border-radius: 50rem;
    margin-left:.5rem;
    display: inline-block;
  }
  @media screen and (max-width: 768px) {
    margin-top:0rem;

  }
`;
export const TSpan = styled.span`
  color: var(--Third_Color);
  font-weight: 500;
`;
export const Paras = styled.p`
  text-align: center;
  margin: 1rem 0rem 7rem 0rem;
  @media screen and (max-width: 768px) {
    margin: 1rem 0rem 5rem 0rem;
    padding:0rem 1rem;


  }
`;
