import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { menuData } from "../../data/MenuData";
import { Button } from "../Button/Button";
import { SwitchBtn } from "../SwitchBtn";
import { DarkMode } from "../DarkMode";
import { GlobalStyle, lightTheme, darkTheme } from "../../globalStyle";
import {
  Nav,
  NavLink,
  NavMenu,
  MenuItem,
  NavMenuLinks,
} from "./Navbar.elements";
import LogoSvg from "./LogoSvg";
import MenuButton from "./MenuToggle/MenuButtton";
import DropDownMenu from "./DropDownMenu/DropDownMenu";
import { motion } from "framer-motion";
import ShubhamResume from "../../images/ShubhamSaini-Resume.pdf";

const Navbar = () => {
  // Menu Button
  const [isOpen, setOpen] = React.useState(false);
  const toggel = () => {
    setOpen(!isOpen);
  };

  // Dark And Light Mode
  const [theme, toggleTheme] = DarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  // Animate NavBar on  Scroll
  const [showAction, setShowAction] = useState(true);
  const [scrollsY, setScrollsY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const yScroll = window.scrollY;
      const isScrollUp = yScroll < scrollsY;
      setShowAction(isScrollUp);
      setScrollsY(yScroll);
    };

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [scrollsY]);

  return (
    <>
    <div className="container-fluid" id="backtotop"></div>

      <DropDownMenu isOpen={isOpen} toggel={toggel} />
      <motion.div
        className="container-fluid"
        animate={{
          opacity: showAction ? 1 : 0,
          position: "sticky",
          top: 0,
          transition: { ease: "anticipate", duration: 0.6, delay: 0.1 },
          y: showAction ? 0 : -100,
        }}
        initial={{ opacity: 0, y: -100 }}
      >
        <div className="container">
          <Nav>
            <NavLink to="/">
              <LogoSvg />
            </NavLink>

            <NavMenu>
              <MenuItem>
                {menuData.map((item, index) => (
                  <NavMenuLinks
                    to={item.link}
                    activeClassName="activeNavMenu"
                    key={index}
                  >
                    {item.title}
                  </NavMenuLinks>
                ))}

                <Button
                  visit={ShubhamResume}
                  target="_blank"
                  buttonStyle="btn--bg"
                >
                  Download CV
                </Button>
              </MenuItem>

              <ThemeProvider theme={themeMode}>
                <GlobalStyle />
                <SwitchBtn theme={theme} toggleTheme={toggleTheme} />
              </ThemeProvider>
              <MenuButton
                className="MenuBarBtn"
                isOpen={isOpen}
                onClick={toggel}
                strokeWidth="4"
                color="var(--H_Color)"
                lineProps={{ strokeLinecap: "round" }}
                transition={{ type: "spring", stiffness: 260, damping: 20 }}
                width="24"
                height="24"
              />
            </NavMenu>
          </Nav>
        </div>
      </motion.div>
    </>
  );
};

export default Navbar;
