import { motion } from "framer-motion";
import styled from "styled-components";

export const AboutSection = styled.section`
  padding: 8rem 0rem 10rem 0rem;
  @media screen and (max-width: 768px) {
    padding: 2rem 0rem;
    .aboutRow {
      flex-direction: column;
      text-align: center;
    }
  }
`;



export const AboutImg = styled(motion.img)`
  max-width: 43rem;
  width: 100%;
  position: relative;
  z-index:2;
  cursor: grab;
`;
export const AboutTagLine = styled.h6`
  font-size: 1.6rem;
  background-color: var(--Second_Color);
  width: fit-content;
  padding: 0rem 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 50rem;
  margin-bottom: 5rem;

  &::before {
    content: "";
    position: relative;
    width: 4rem;
    height: 0.3rem;
    background-color: var(--H_Color);
    border-radius: 50rem;
    display: inline-block;
    margin-right: 2rem;
    top: -0.36rem;
  }
  @media screen and (max-width: 768px) {
    margin: -5rem auto 4rem auto;

    &::before {
      display: none;
    }
  }
`;
export const AboutTitle = styled.h3``;

export const AboutPara = styled.p`
  margin: 2rem 0rem 5rem 0rem;
`;
