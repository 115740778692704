import styled from "styled-components";

export const ComProfileSection = styled.section`
  @media screen and (max-width: 768px) {
    padding: 4rem 0rem;
    margin-top: -8rem;
  }
`;

export const ComProfileRow = styled.div`
  @media screen and (max-width: 768px) {
  }
`;
export const ComProfileCol = styled.div`
  background-color: var(--Bg_Color_Three);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin: 8rem 0rem 24rem 0rem;
  border-radius: 0rem 3rem 0rem 3rem;
  padding: 6rem;
  position: relative;
  &::before {
    content: "";
    background-image: linear-gradient(
      to top,
      var(--Bg_Color_Three) 50%,
      var(--Third_Color) 50%
    );
    width: 50%;
    height: 5rem;
    right: 0rem;
    display: inline-block;
    top: -2.5rem;
    border-radius: 50rem;
    left: 0rem;
    position: absolute;
  }
  &::after {
    content: "";
    background-image: linear-gradient(
      to bottom,
      var(--Bg_Color_Three) 50%,
      var(--Third_Color) 50%
    );
    width: 50%;
    height: 5rem;
    right: 0rem;
    display: inline-block;
    bottom: -2.5rem;
    border-radius: 50rem;
    position: absolute;
  }
  @media screen and (max-width: 768px) {
    margin: 5rem 0rem 20rem 0rem;
    padding: 6rem 2.2rem;
  }
  &.m_Bottom {
    margin-bottom: 10rem;
  }
`;

export const ComProfileTitle = styled.h6`
  font-size: 1.6rem;
  background-color: var(--Third_Color);
  width: fit-content;
  padding: 0rem 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 50rem;
  color: white;
  &::before {
    content: "";
    position: relative;
    width: 2rem;
    height: 0.3rem;
    background-color: white;
    border-radius: 50rem;
    display: inline-block;
    margin-right: 1.6rem;
    top: -0.36rem;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;
export const ComProfilePara = styled.p`
  margin: 4rem 0rem 0rem 0rem;
  width: fit-content;
  border-radius: 2rem;
  padding: 3rem;
  background-color: var(--First_Color);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  word-break: break-word;
  @media screen and (max-width: 768px) {
    padding: 2.2rem;
  }
  a {
    color: var(--Third_Color);
    font-weight: 500;
  }
`;
export const ComProfileHeading = styled.span`
  display: block;
  font-size: 1.6rem;
  line-height: 3rem;
  margin-bottom: 1rem;
`;
export const ComProfileSkillPara = styled.h3`
  margin: 4rem 2rem 0rem 0rem;
  font-size: 1.4rem;
  width: fit-content;
  display: inline-block;
  border-radius: 50rem;
  padding: 0.2rem 3rem;
  background-color: var(--First_Color);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
`;
