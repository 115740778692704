import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  max-width: 28rem;
  width: 100%;
  
  @media screen and (max-width: 360px) {
    max-width: 24rem;

  }
`;
const Path = styled.path`
  &.cls-1 {
    fill: #ff4a57;
  }
  &.cls-2 {
    fill: var(--H_Color);
  }
`;

const LogoSvg = () => {
  return (
    <>
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 532.48 185.3">
        <defs>
          <style></style>
        </defs>
        <title>Logo | Shubham Saini</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <Path
              className="cls-1"
              d="M142.44,25.13c-.48.48-1.68,0-2.64-1.08s-1.32-2.28-.84-2.76a3.27,3.27,0,0,0,1-2,6.73,6.73,0,0,0-1.08-4.8,15.31,15.31,0,0,0-5.52-5C113.52-1.75,74.52,8,55.2,17,46,21.29,23.28,34.12,29.52,47.32c2.88,6.24,13,9.72,22.8,13.08,5.64,2,11.28,4,16,6.6,34,19.08-8.4,60.12-41.76,60.72C14.4,128.32.24,122.56,0,110.44c-.12-5.52,4.2-12.72,14.88-21.24.48-.48,1.68.12,2.64,1.2s1.32,2.28.72,2.64C7.92,101.32,3.84,108,4,113c.12,7.68,13.44,8.52,18.72,8.28,29.16-.72,71.16-36.6,46.08-50.76a106.88,106.88,0,0,0-15.12-6.12c-11.4-4-23-7.92-27.6-17.64C18.72,31,39.72,17.33,51.24,11.93c20.64-9.6,60.48-18,81.6-6a23.86,23.86,0,0,1,9,8.52,12.14,12.14,0,0,1,2,7.68A5.05,5.05,0,0,1,142.44,25.13Z"
            />
            <Path
              className="cls-1"
              d="M119.76,27.89c.36-.6,1.56-.24,2.52.72s1.56,2.28,1.2,2.88L97.2,74.08c6.6-5,13.32-8,14-.12.12,1.08.24,8.88.24,8.88.48.6,4.32-.36,14.88-10.2.48-.48,1.2-1.56,2.16-.6s1,1.92.48,2.4c-11.88,11-17.64,13.8-20.4,10.92-2.88-3.12-1.92-8.76-2.28-13C106,69.4,86.88,90.88,86.76,91c-.36.48-1.44.12-2.4-.84s-1.56-2.28-1.2-2.88Z"
            />
            <Path
              className="cls-1"
              d="M132.84,62.2c.48-.48,1.68,0,2.64,1s1.32,2.28,1,2.88c-9.12,10.44-9.84,17.64-9,18.36,1.44,1.2,10.68-4.68,17.16-15.6l.36-.48c.48-.48,1.68-.12,2.64,1s1.44,2.28,1,2.76a4.28,4.28,0,0,1-.84.84c-1.56,1.92-10.56,14.64-1.56,10.32a41.62,41.62,0,0,0,13.32-10.8c.48-.6,1-1.8,2-.72s.36,1.68-.12,2.16c-3.24,4.2-7.8,9.84-12.6,12.12-4.2,2-9.48,1.08-10.2-4.08-11.88,10.2-21.6,8.28-15-6.36A54.68,54.68,0,0,1,132.84,62.2Z"
            />
            <Path
              className="cls-1"
              d="M184.92,28.37c.36-.6,1.44-.24,2.52.72s1.56,2.28,1.2,2.88L161.76,75.4c4.92-4.56,10-8.76,13.8-10.44,2.16-1,4-.84,5.4.24l.12.12a6.81,6.81,0,0,1,2.64,5.52,15.25,15.25,0,0,1-.72,4.92c-1.92,6.24-6.84,14-12.12,15.24-7.32,1.8-10.2-2.28-11.4-6.24-5.76,5.76-10.08,10.8-10.2,10.8-.36.48-1.44.12-2.4-.84s-1.56-2.28-1.2-2.88ZM178.8,68.92c-4.44,2-10.56,7.32-16.2,12.72,0,1.32.36,6.48,5.4,5.28,4.68-1.2,9.36-8.52,11-14.4a19,19,0,0,0,.84-4.08C179.52,68.56,179.16,68.8,178.8,68.92Zm1.8-.72a.37.37,0,0,0-.24.12c.24,0,.36.12.24,0Z"
            />
            <Path
              className="cls-1"
              d="M215.88,27.89c.36-.6,1.56-.24,2.52.72s1.56,2.28,1.2,2.88L193.32,74.08c6.6-5,13.32-8,14-.12.12,1.08.24,8.88.24,8.88.48.6,4.32-.36,14.88-10.2.48-.48,1.2-1.56,2.16-.6s1,1.92.48,2.4c-11.88,11-17.64,13.8-20.4,10.92-2.88-3.12-1.92-8.76-2.28-13-.36-3-19.44,18.48-19.56,18.6-.36.48-1.44.12-2.4-.84s-1.56-2.28-1.2-2.88Z"
            />
            <Path
              className="cls-1"
              d="M242.16,70.48c.48,1.2.12,1.92-.84,1.68a4.66,4.66,0,0,1-2.64-2.4c-2.4-2.28-8.28.48-10.44,2.28A23.36,23.36,0,0,0,220,85.48c4.2-1.2,15.12-13.92,15.12-13.92.48-.48,1.56-.12,2.64,1,.84,1,1.32,2,1,2.64a12.19,12.19,0,0,0-1,7.08C238.2,85.12,253.8,72,254,71.92a1.58,1.58,0,0,1,2.4-.12c1,1.08.84,2,.36,2.52-7.92,7.08-12.6,11.28-15.84,13-5.52,2.88-9.72,1.2-9.6-4.68-4.32,4.08-8.76,7.8-11.4,6.6a7,7,0,0,1-2.4-1.8,6.58,6.58,0,0,1-1.44-5,13.91,13.91,0,0,1,1.32-4.56,28.46,28.46,0,0,1,7.44-9.6C230.28,63.76,240.12,63.52,242.16,70.48Z"
            />
            <Path
              className="cls-1"
              d="M257.16,62.68c.24-.6,1.44-.24,2.4.72s1.68,2.16,1.32,2.88L258.72,70c3.72-3.6,7.32-6.6,9.6-7.44,7.44-2.52,6.24,5.64,3.84,13a5.61,5.61,0,0,1,1.2-.6,7.63,7.63,0,0,1,2.88-.84c1.92-.12,3.48.84,4.56,3.12.6,1.32.6,3.36-.36,6.12-.12.24,12.84-10.44,13-10.44.48-.48,1.56-1.8,2.52-.72s1,1.68.48,2.16c-.12.12-28.8,30.36-19.8,5.64.12-.48.24-.84.36-1.32l-.48.24c-3.24,1.68-7.44,5.16-8.28,6.6v.12c-.36.72-1.44.48-2.52-.48s-1.56-2.28-1.32-2.88c0-.12,7.32-15.72,7-15.6-6.48,2.28-23.76,23.4-23.88,23.52-.36.48-1.44.12-2.4-.72s-1.56-2.28-1.2-2.88Z"
            />
            <Path
              className="cls-2"
              d="M473.39,25.13c-.48.48-1.68,0-2.64-1.08s-1.32-2.28-.84-2.76a3.27,3.27,0,0,0,1-2,6.73,6.73,0,0,0-1.08-4.8,15.31,15.31,0,0,0-5.52-5C444.47-1.75,405.47,8,386.15,17c-9.24,4.32-31.92,17.15-25.68,30.35,2.88,6.24,13,9.72,22.8,13.08,5.64,2,11.28,4,16,6.6,34,19.08-8.4,60.12-41.76,60.72-12.12.6-26.28-5.16-26.52-17.28-.12-5.52,4.2-12.72,14.88-21.24.48-.48,1.68.12,2.64,1.2s1.32,2.28.72,2.64c-10.32,8.28-14.4,15-14.28,19.92.12,7.68,13.44,8.52,18.72,8.28,29.16-.72,71.16-36.6,46.08-50.76a106.88,106.88,0,0,0-15.12-6.12c-11.4-4-23-7.92-27.6-17.64-7.32-15.71,13.68-29.39,25.2-34.79,20.64-9.6,60.48-18,81.6-6a23.86,23.86,0,0,1,9,8.52,12.14,12.14,0,0,1,2,7.68A5.05,5.05,0,0,1,473.39,25.13Z"
            />
            <Path
              className="cls-2"
              d="M444,70.48c.48,1.2.12,1.92-.84,1.68a4.66,4.66,0,0,1-2.64-2.4c-2.4-2.28-8.28.48-10.44,2.28a23.36,23.36,0,0,0-8.28,13.44c4.2-1.2,15.12-13.92,15.12-13.92.48-.48,1.56-.12,2.64,1,.84,1,1.32,2,1,2.64a12.19,12.19,0,0,0-1,7.08c.48,2.88,16.08-10.2,16.32-10.32a1.58,1.58,0,0,1,2.4-.12c1,1.08.84,2,.36,2.52-7.92,7.08-12.6,11.28-15.84,13-5.52,2.88-9.72,1.2-9.6-4.68-4.32,4.08-8.76,7.8-11.4,6.6a7,7,0,0,1-2.4-1.8,6.58,6.58,0,0,1-1.44-5,13.91,13.91,0,0,1,1.32-4.56,28.46,28.46,0,0,1,7.44-9.6C432.11,63.76,442,63.52,444,70.48Z"
            />
            <Path
              className="cls-2"
              d="M462.83,63.16c.6-.48,1.8,0,2.76,1.08s1.32,2.28.72,2.76a48.9,48.9,0,0,0-9.6,12.48c-1.68,3.36-1.56,5.28-.36,5.88,4.92.72,18.24-12.24,18.24-12.36.48-.48,1.32-1.44,2.28-.36s.6,1.44.12,1.92c-.12,0-20,21.6-25.2,12.6-1.68-3-.84-7.08,1.2-11.16A47.08,47.08,0,0,1,462.83,63.16Zm7.44-3.48c-.48.6-1.68.12-2.64-.84s-1.44-2.28-1-2.88l6.72-7.68c.48-.6,1.56-.12,2.64.84s1.32,2.28,1,2.88Z"
            />
            <Path
              className="cls-2"
              d="M478.07,65.8c.36-.6,1.56-.36,2.52.6s1.56,2.28,1.2,2.88l-3,5.4c6.48-5.4,13.56-10.68,16.2-10h.24c3.6,1.08,4.2,3.36,3.36,6.36a34.75,34.75,0,0,1-2,4.68c-1.56,3.12-3.36,6.6-3.36,6.6,3.84,2.52,16.8-8.88,16.92-8.88.36-.48,1.68-2,2.64-1s.36,1.2-.12,1.8c0,0-13.08,15.48-19.32,11.4-5.4-3.48-2.76-8.76-.48-13.44a30.69,30.69,0,0,0,1.32-2.88c-6.84,3.6-22.8,18.84-23.28,19.32s-1.44.12-2.4-.72c-1-1.08-1.56-2.28-1.2-2.88Z"
            />
            <Path
              className="cls-2"
              d="M517.79,63.16c.6-.48,1.8,0,2.76,1.08s1.32,2.28.72,2.76a48.9,48.9,0,0,0-9.6,12.48c-1.68,3.36-1.56,5.28-.36,5.88,4.92.72,18.24-12.24,18.24-12.36.48-.48,1.32-1.44,2.28-.36s.6,1.44.12,1.92c-.12,0-20,21.6-25.2,12.6-1.68-3-.84-7.08,1.2-11.16A47.08,47.08,0,0,1,517.79,63.16Zm7.44-3.48c-.48.6-1.68.12-2.64-.84s-1.44-2.28-1-2.88l6.72-7.68c.48-.6,1.56-.12,2.64.84s1.32,2.28,1,2.88Z"
            />
            <Path
              className="cls-2"
              d="M182.82,175.8l-5.23-5.38,5.23-5.4h4.25l-5.26,5.4,5.26,5.38Z"
            />
            <Path
              className="cls-2"
              d="M231.13,162,227,179h-4.17l-3.17-12.29L216.45,179h-4.16L208.14,162h3.67l2.6,13.13L217.77,162h3.72l3.38,13.13L227.46,162Z"
            />
            <Path
              className="cls-2"
              d="M253,173h-9.74a3.59,3.59,0,0,0,1,2.61,3.07,3.07,0,0,0,2.21.84,3.22,3.22,0,0,0,2-.57,2.62,2.62,0,0,0,1-1.56H253a5.7,5.7,0,0,1-1.12,2.49,5.92,5.92,0,0,1-2.23,1.73,7,7,0,0,1-3,.62,7.09,7.09,0,0,1-3.45-.82,5.92,5.92,0,0,1-2.36-2.37,7.42,7.42,0,0,1-.84-3.62,7.53,7.53,0,0,1,.84-3.64,5.77,5.77,0,0,1,2.36-2.36,7.09,7.09,0,0,1,3.45-.82,7,7,0,0,1,3.45.82,5.78,5.78,0,0,1,2.28,2.24A6.61,6.61,0,0,1,253,173Zm-4.14-4a3.24,3.24,0,0,0-2.27-.84,3.31,3.31,0,0,0-2.3.84,3.43,3.43,0,0,0-1,2.47h6.45A3,3,0,0,0,248.88,169Z"
            />
            <Path
              className="cls-2"
              d="M273.71,166.32a5.57,5.57,0,0,1,2.1,2.36,9.07,9.07,0,0,1,0,7.27,5.65,5.65,0,0,1-2.1,2.37,5.76,5.76,0,0,1-3,.81,5,5,0,0,1-2.87-.8,4.45,4.45,0,0,1-1.7-2.15V179h-3.38V161.21h3.38v7.22a4.38,4.38,0,0,1,1.7-2.14,5.12,5.12,0,0,1,2.87-.79A5.68,5.68,0,0,1,273.71,166.32Zm-6.61,3.18a3.87,3.87,0,0,0-1,2.82,3.83,3.83,0,0,0,1,2.79,3.62,3.62,0,0,0,5.07,0,4,4,0,0,0,1-2.82,4,4,0,0,0-1-2.84,3.61,3.61,0,0,0-5.07,0Z"
            />
            <Path
              className="cls-2"
              d="M312.44,174.91a7.3,7.3,0,0,1-3.06,3,10,10,0,0,1-4.76,1.07h-6V162h6a10.22,10.22,0,0,1,4.76,1,7.23,7.23,0,0,1,3.06,3,9.85,9.85,0,0,1,0,8.89Zm-3.87-.41a5.32,5.32,0,0,0,1.5-4,5.37,5.37,0,0,0-1.5-4.06,5.87,5.87,0,0,0-4.24-1.44H302v11h2.35A5.87,5.87,0,0,0,308.57,174.5Z"
            />
            <Path
              className="cls-2"
              d="M335.63,173h-9.75a3.67,3.67,0,0,0,1,2.61,3.11,3.11,0,0,0,2.21.84,3.23,3.23,0,0,0,2-.57,2.56,2.56,0,0,0,1-1.56h3.58a5.71,5.71,0,0,1-1.13,2.49,5.81,5.81,0,0,1-2.22,1.73,7.67,7.67,0,0,1-6.47-.2,5.89,5.89,0,0,1-2.35-2.37,7.42,7.42,0,0,1-.84-3.62,7.53,7.53,0,0,1,.84-3.64,5.75,5.75,0,0,1,2.35-2.36,7.11,7.11,0,0,1,3.46-.82,6.92,6.92,0,0,1,3.44.82,5.65,5.65,0,0,1,2.28,2.24,6.56,6.56,0,0,1,.69,4.41Zm-4.14-4a3.25,3.25,0,0,0-2.27-.84,3.31,3.31,0,0,0-2.3.84,3.43,3.43,0,0,0-1,2.47h6.46A3,3,0,0,0,331.49,169Z"
            />
            <Path
              className="cls-2"
              d="M351.13,175.58l3.36-9.91h3.6L353.17,179H349l-4.92-13.3h3.63Z"
            />
            <Path
              className="cls-2"
              d="M379.55,173H369.8a3.67,3.67,0,0,0,1,2.61,3.11,3.11,0,0,0,2.21.84,3.23,3.23,0,0,0,2-.57,2.56,2.56,0,0,0,1-1.56h3.58a5.71,5.71,0,0,1-1.13,2.49,5.81,5.81,0,0,1-2.22,1.73,7.67,7.67,0,0,1-6.47-.2,5.89,5.89,0,0,1-2.35-2.37,7.42,7.42,0,0,1-.84-3.62,7.53,7.53,0,0,1,.84-3.64,5.75,5.75,0,0,1,2.35-2.36,7.11,7.11,0,0,1,3.46-.82,6.92,6.92,0,0,1,3.44.82,5.65,5.65,0,0,1,2.28,2.24,6.56,6.56,0,0,1,.69,4.41Zm-4.14-4a3.25,3.25,0,0,0-2.27-.84,3.31,3.31,0,0,0-2.3.84,3.43,3.43,0,0,0-1,2.47h6.46A3,3,0,0,0,375.41,169Z"
            />
            <Path className="cls-2" d="M392.65,161.21V179h-3.38V161.21Z" />
            <Path
              className="cls-2"
              d="M412.56,166.32a6,6,0,0,1,2.44,2.36,7.21,7.21,0,0,1,.88,3.64,7.11,7.11,0,0,1-.88,3.62,6.18,6.18,0,0,1-2.44,2.37,7.84,7.84,0,0,1-7,0,6,6,0,0,1-2.44-2.37,7.12,7.12,0,0,1-.89-3.62,7.22,7.22,0,0,1,.89-3.64,5.9,5.9,0,0,1,2.44-2.36,7.84,7.84,0,0,1,7,0Zm-5.89,3.11a4.71,4.71,0,0,0,0,5.78,3.38,3.38,0,0,0,4.8,0,4.75,4.75,0,0,0,0-5.78,3.38,3.38,0,0,0-4.8,0Z"
            />
            <Path
              className="cls-2"
              d="M436.45,166.32a5.44,5.44,0,0,1,2.1,2.36,9.07,9.07,0,0,1,0,7.27,5.52,5.52,0,0,1-2.1,2.37,5.74,5.74,0,0,1-3,.81,5,5,0,0,1-2.87-.8,4.38,4.38,0,0,1-1.69-2.15v9.12h-3.39V165.67h3.39v2.76a4.31,4.31,0,0,1,1.69-2.14,5.12,5.12,0,0,1,2.87-.79A5.66,5.66,0,0,1,436.45,166.32Zm-6.61,3.18a3.87,3.87,0,0,0-1,2.82,3.83,3.83,0,0,0,1,2.79,3.63,3.63,0,0,0,5.08,0,4,4,0,0,0,.95-2.82,4.06,4.06,0,0,0-.95-2.84,3.62,3.62,0,0,0-5.08,0Z"
            />
            <Path
              className="cls-2"
              d="M461.29,173h-9.74a3.63,3.63,0,0,0,1,2.61,3.07,3.07,0,0,0,2.2.84,3.23,3.23,0,0,0,2-.57,2.62,2.62,0,0,0,1-1.56h3.57a5.7,5.7,0,0,1-1.12,2.49,6,6,0,0,1-2.22,1.73,7.67,7.67,0,0,1-6.47-.2,5.77,5.77,0,0,1-2.35-2.37,7.31,7.31,0,0,1-.84-3.62,7.42,7.42,0,0,1,.84-3.64,5.63,5.63,0,0,1,2.35-2.36,7.09,7.09,0,0,1,3.45-.82,7,7,0,0,1,3.45.82,5.78,5.78,0,0,1,2.28,2.24,6.61,6.61,0,0,1,.68,4.41Zm-4.14-4a3.24,3.24,0,0,0-2.27-.84,3.31,3.31,0,0,0-2.3.84,3.43,3.43,0,0,0-1,2.47H458A3,3,0,0,0,457.15,169Z"
            />
            <Path
              className="cls-2"
              d="M476.36,166.21a5.23,5.23,0,0,1,2.74-.73v3.62h-1q-3.72,0-3.72,3.46V179H471v-13.3h3.38v2.54A5.72,5.72,0,0,1,476.36,166.21Z"
            />
            <Path
              className="cls-2"
              d="M508.59,156.24l-6.5,27.07h-3.26l6.5-27.07Z"
            />
            <Path
              className="cls-2"
              d="M516.13,165h4.22l5.26,5.4-5.26,5.38h-4.22l5.23-5.38Z"
            />
          </g>
        </g>
      </Svg>
    </>
  );
};

export default LogoSvg;
