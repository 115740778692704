import { motion } from "framer-motion";
import { HomeScrollTab, HeroSection, About, Skills, Projects, Contact } from "../components";
import { animationOne, transition } from "./Animation";

const Home = () => {
  return (
    <>
    <motion.div animate="in" initial="out" exit="out" variants={animationOne} transition={transition}>

      <HomeScrollTab />
      <HeroSection />
      <About />
      <Skills />
      <Projects />
      {/* <Contact /> */}
    </motion.div>
    </>
  );
};

export default Home;
