import React from "react";
import { Button } from "../../Button/Button";

import { menuData } from "../../../data/MenuData";

import {
  DropContainer,
  DropBox,
  DropLogo,
  DropMenuLinks,
} from "./DropDownMenu.element";
import NameSvg from "../../About/NameSvg";
import ShubhamResume from "../../../images/ShubhamSaini-Resume.pdf";
import { Link } from "react-router-dom";
const DropDownMenu = ({ isOpen, toggel }) => {
  return (
    <>
      <DropContainer isOpen={isOpen} onClick={toggel}>
        <DropBox>
          <DropLogo>
            <Link to='/' style={{lineHeight:0}}>
            <NameSvg />
            </Link>
          </DropLogo>

          {menuData.map((dropItem, index) => (
            <DropMenuLinks
              to={dropItem.link}
              activeClassName="activeNavMenu"
              key={index}
            >
              {dropItem.title}
            </DropMenuLinks>
          ))}
          <Button visit={ShubhamResume} target="_blank" buttonStyle="btn--bg">
            Download CV
          </Button>
        </DropBox>
      </DropContainer>
    </>
  );
};

export default DropDownMenu;
