import React from "react";
import { Button } from "../Button/Button";
import {
  ErrorSection,
  ErrorBox,
  ErrorTitle,
  ErrorPara,
} from "./Error404.element";

import PageNotFound from '../../images/page_not_found.svg'
const Error404 = () => {
  return (
    <>
      <ErrorSection>
        <ErrorBox>
          <ErrorTitle><img src={PageNotFound} alt="404_Page_Not_Found"/></ErrorTitle>
          <ErrorPara>
            Oops! Page Not Found
          </ErrorPara>
          <Button visit="/" buttonStyle="btn--primary">
            Go Back Home
          </Button>
        </ErrorBox>
      </ErrorSection>
    </>
  );
};

export default Error404;
