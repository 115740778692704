import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  max-width: 30rem;
  width: 100%;
`;
const Path = styled.path`
  &.cls-1 {
    fill: #ff4a57;
  }
  &.cls-2 {
    fill: var(--H_Color);
  }
`;

const NameSvg = () => {
  return (
    <>
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 887.47 212.95">
        <defs>
          <style></style>
        </defs>
        <title>Shubham Saini</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <Path
              className="cls-1"
              d="M237.4,41.88c-.8.8-2.8,0-4.4-1.8-1.6-1.6-2.2-3.8-1.4-4.6a5.45,5.45,0,0,0,1.6-3.4,11.21,11.21,0,0,0-1.8-8c-1.6-2.8-4.6-5.6-9.2-8.4-33-18.6-98-2.4-130.2,12.6-15.4,7.2-53.2,28.6-42.8,50.6,4.8,10.4,21.6,16.2,38,21.8,9.4,3.4,18.8,6.6,26.6,11,56.6,31.8-14,100.19-69.6,101.19-20.2,1-43.8-8.6-44.2-28.8-.2-9.2,7-21.2,24.8-35.39.8-.8,2.8.2,4.4,2,1.6,1.6,2.2,3.79,1.2,4.4-17.2,13.79-24,25-23.8,33.19.2,12.8,22.4,14.2,31.2,13.8,48.6-1.2,118.6-61,76.8-84.59-7-3.8-16-7-25.2-10.2-19-6.6-38.4-13.2-46-29.4-12.2-26.2,22.8-49,42-58,34.4-16,100.8-30,136-10,7.2,4.2,12.2,9.2,15,14.2,3,4.8,3.8,9.4,3.4,12.8A8.42,8.42,0,0,1,237.4,41.88Z"
            />
            <Path
              className="cls-1"
              d="M199.6,46.48c.6-1,2.6-.4,4.2,1.2,1.8,1.6,2.6,3.8,2,4.8l-43.8,71c11-8.4,22.2-13.4,23.4-.2.2,1.8.4,14.8.4,14.8.8,1,7.2-.6,24.8-17,.8-.8,2-2.6,3.6-1,1.6,1.8,1.6,3.2.8,4-19.8,18.4-29.4,23-34,18.2-4.8-5.2-3.2-14.6-3.8-21.6-.6-5-32.4,30.79-32.6,31-.6.79-2.4.2-4-1.4-1.8-1.6-2.6-3.8-2-4.8Z"
            />
            <Path
              className="cls-1"
              d="M221.4,103.68c.8-.8,2.8,0,4.4,1.6,1.6,1.8,2.2,3.8,1.6,4.8-15.2,17.4-16.4,29.4-15,30.6,2.4,2,17.8-7.8,28.6-26l.6-.8c.8-.8,2.8-.2,4.4,1.6,1.6,1.6,2.4,3.8,1.6,4.6a7.13,7.13,0,0,1-1.4,1.4c-2.6,3.2-17.6,24.4-2.6,17.2a69.37,69.37,0,0,0,22.2-18c.8-1,1.6-3,3.4-1.2,1.6,1.6.6,2.8-.2,3.6-5.4,7-13,16.4-21,20.2-7,3.4-15.8,1.8-17-6.8-19.8,17-36,13.8-25-10.6C209.6,117.88,216.2,109.28,221.4,103.68Z"
            />
            <Path
              className="cls-1"
              d="M308.2,47.28c.6-1,2.4-.4,4.2,1.2,1.6,1.6,2.6,3.8,2,4.8l-44.8,72.4c8.2-7.6,16.6-14.6,23-17.4,3.6-1.6,6.6-1.4,9,.4l.2.2a11.35,11.35,0,0,1,4.4,9.2,25.42,25.42,0,0,1-1.2,8.2c-3.2,10.4-11.4,23.4-20.2,25.4-12.2,3-17-3.8-19-10.4-9.6,9.6-16.8,18-17,18-.6.8-2.4.19-4-1.41-1.8-1.59-2.6-3.79-2-4.79ZM298,114.88c-7.4,3.4-17.6,12.2-27,21.2,0,2.2.6,10.8,9,8.8,7.8-2,15.6-14.2,18.4-24a31.69,31.69,0,0,0,1.4-6.8C299.2,114.28,298.6,114.68,298,114.88Zm3-1.2a.62.62,0,0,0-.4.2c.4,0,.6.2.4,0Z"
            />
            <Path
              className="cls-1"
              d="M359.8,46.48c.6-1,2.6-.4,4.2,1.2,1.8,1.6,2.6,3.8,2,4.8l-43.8,71c11-8.4,22.2-13.4,23.4-.2.2,1.8.4,14.8.4,14.8.8,1,7.2-.6,24.8-17,.8-.8,2-2.6,3.6-1,1.6,1.8,1.6,3.2.8,4-19.8,18.4-29.4,23-34,18.2-4.8-5.2-3.2-14.6-3.8-21.6-.6-5-32.4,30.79-32.6,31-.6.79-2.4.2-4-1.4-1.8-1.6-2.6-3.8-2-4.8Z"
            />
            <Path
              className="cls-1"
              d="M403.6,117.48c.8,2,.2,3.2-1.4,2.8a7.77,7.77,0,0,1-4.4-4c-4-3.8-13.8.8-17.4,3.8-7.4,6-11.6,13.2-13.8,22.4,7-2,25.2-23.2,25.2-23.2.8-.8,2.6-.2,4.4,1.6,1.4,1.6,2.2,3.4,1.6,4.4-.4,1-2.8,6.2-1.6,11.8.8,4.8,26.8-17,27.2-17.2.8-.8,2.4-1.8,4-.2,1.6,1.8,1.4,3.4.6,4.2-13.2,11.8-21,18.8-26.4,21.6-9.2,4.8-16.2,2-16-7.8-7.2,6.8-14.6,13-19,11a11.71,11.71,0,0,1-4-3,11,11,0,0,1-2.4-8.4,23.18,23.18,0,0,1,2.2-7.6,47.43,47.43,0,0,1,12.4-16C383.8,106.28,400.2,105.88,403.6,117.48Z"
            />
            <Path
              className="cls-1"
              d="M428.6,104.48c.4-1,2.4-.4,4,1.2,1.8,1.6,2.8,3.6,2.2,4.8l-3.6,6.2c6.2-6,12.2-11,16-12.4,12.4-4.2,10.4,9.4,6.4,21.6a9.12,9.12,0,0,1,2-1,12.72,12.72,0,0,1,4.8-1.4c3.2-.2,5.79,1.4,7.59,5.2,1,2.2,1,5.6-.59,10.2-.21.4,21.39-17.4,21.59-17.4.8-.8,2.61-3,4.2-1.2,1.8,1.6,1.6,2.8.8,3.6-.2.2-48,50.59-33,9.4.19-.8.4-1.4.6-2.2l-.8.4c-5.4,2.8-12.4,8.6-13.8,11v.2c-.6,1.2-2.4.8-4.2-.8s-2.6-3.8-2.2-4.8c0-.2,12.2-26.2,11.6-26-10.8,3.8-39.6,39-39.8,39.2-.6.8-2.4.19-4-1.2-1.6-1.6-2.6-3.8-2-4.8Z"
            />
            <Path
              className="cls-2"
              d="M789,41.88c-.8.8-2.8,0-4.4-1.8-1.6-1.6-2.2-3.8-1.4-4.6a5.45,5.45,0,0,0,1.6-3.4,11.21,11.21,0,0,0-1.8-8c-1.6-2.8-4.6-5.6-9.2-8.4-33-18.6-98-2.4-130.2,12.6-15.4,7.2-53.2,28.6-42.8,50.6,4.8,10.4,21.6,16.2,38,21.8,9.4,3.4,18.8,6.6,26.6,11,56.6,31.8-14,100.19-69.6,101.19-20.2,1-43.8-8.6-44.2-28.8-.2-9.2,7-21.2,24.8-35.39.8-.8,2.8.2,4.4,2,1.6,1.6,2.2,3.79,1.2,4.4-17.2,13.79-24,25-23.8,33.19.2,12.8,22.4,14.2,31.2,13.8,48.6-1.2,118.6-61,76.8-84.59-7-3.8-16-7-25.2-10.2-19-6.6-38.4-13.2-46-29.4-12.2-26.2,22.8-49,42-58,34.4-16,100.8-30,136-10,7.2,4.2,12.2,9.2,15,14.2,3,4.8,3.8,9.4,3.4,12.8A8.42,8.42,0,0,1,789,41.88Z"
            />
            <Path
              className="cls-2"
              d="M740,117.48c.8,2,.2,3.2-1.4,2.8a7.77,7.77,0,0,1-4.4-4c-4-3.8-13.8.8-17.4,3.8-7.4,6-11.6,13.2-13.8,22.4,7-2,25.2-23.2,25.2-23.2.8-.8,2.6-.2,4.4,1.6,1.4,1.6,2.2,3.4,1.6,4.4-.4,1-2.8,6.2-1.6,11.8.8,4.8,26.8-17,27.2-17.2.8-.8,2.4-1.8,4-.2,1.6,1.8,1.4,3.4.6,4.2-13.2,11.8-21,18.8-26.4,21.6-9.2,4.8-16.2,2-16-7.8-7.2,6.8-14.6,13-19,11a11.71,11.71,0,0,1-4-3,11,11,0,0,1-2.4-8.4,23.18,23.18,0,0,1,2.2-7.6,47.43,47.43,0,0,1,12.4-16C720.19,106.28,736.59,105.88,740,117.48Z"
            />
            <Path
              className="cls-2"
              d="M771.39,105.28c1-.8,3,0,4.6,1.8s2.2,3.8,1.2,4.6c-4.4,3.8-11.6,12.4-16,20.8-2.8,5.6-2.6,8.8-.6,9.8,8.2,1.2,30.4-20.4,30.4-20.6.8-.8,2.2-2.4,3.8-.6,1.8,1.8,1,2.4.2,3.2-.2,0-33.4,36-42,21-2.8-5-1.4-11.8,2-18.6C759.39,118.08,766.79,109.08,771.39,105.28Zm12.4-5.8c-.8,1-2.8.2-4.4-1.4-1.6-1.8-2.4-3.8-1.6-4.8L789,80.48c.8-1,2.6-.2,4.4,1.4,1.6,1.8,2.2,3.8,1.6,4.8Z"
            />
            <Path
              className="cls-2"
              d="M796.78,109.68c.6-1,2.6-.6,4.21,1,1.79,1.6,2.59,3.8,2,4.8l-5,9c10.79-9,22.59-17.8,27-16.6h.4c6,1.8,7,5.6,5.6,10.6a57.91,57.91,0,0,1-3.4,7.8c-2.6,5.2-5.59,11-5.59,11,6.39,4.2,28-14.8,28.19-14.8.6-.8,2.8-3.4,4.4-1.6,1.8,1.6.6,2-.2,3,0,0-21.8,25.8-32.2,19-9-5.8-4.6-14.6-.8-22.4a51.14,51.14,0,0,0,2.2-4.8c-11.4,6-38,31.4-38.8,32.2-.59.8-2.39.2-4-1.2-1.59-1.8-2.59-3.8-2-4.8Z"
            />
            <Path
              className="cls-2"
              d="M863,105.28c1-.8,3,0,4.6,1.8s2.2,3.8,1.2,4.6c-4.4,3.8-11.6,12.4-16,20.8-2.8,5.6-2.6,8.8-.6,9.8,8.2,1.2,30.4-20.4,30.4-20.6.8-.8,2.2-2.4,3.8-.6,1.8,1.8,1,2.4.2,3.2-.2,0-33.4,36-42,21-2.8-5-1.4-11.8,2-18.6C851,118.08,858.38,109.08,863,105.28Zm12.4-5.8c-.8,1-2.8.2-4.4-1.4-1.6-1.8-2.4-3.8-1.6-4.8l11.2-12.8c.8-1,2.6-.2,4.4,1.4,1.6,1.8,2.2,3.8,1.6,4.8Z"
            />
          </g>
        </g>
      </Svg>
    </>
  );
};

export default NameSvg;
