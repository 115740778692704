import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { useState } from "react";

import { HomeProjectData } from "../../data/HomeProjectData";
import css from "../../HomeProjectsCss.module.css";
import Heading from "../Heading/Heading";

const Projects = () => {
  const [selectedId, setSelectedId] = useState(null);

  return (
    <>
      <div className="container" id="projects">
      <Heading
          hTitleOne="My "
          hSpan="Projects"
          hTitleTwo=""
          hPara="See my work"
        />
        <AnimateSharedLayout type="crossfade">
          <main key="main" className={css.main}>
            {HomeProjectData.map((card) => (
              <motion.div
                key={card.id}
                className={card.css}
                layoutId={card.id}
                onClick={() => setSelectedId(card.id)}
              >
                <motion.div className={css.cellTop}>
                  <img className={css.pImg} src={card.img} alt={card.alt} />
                </motion.div>
              </motion.div>
            ))}
          </main>

          <AnimatePresence>
            {selectedId !== null && (
              <>
                <motion.div
                  key="ovbg"
                  className={css.overlayBg}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />

                <motion.div
                  key={`overlay-${selectedId}`} 
                  layoutId={selectedId}
                  className={`${
                    HomeProjectData.find((card) => card.id === selectedId).css
                  }
                ${css.overlay}`}
                  onClick={() => setSelectedId(null)}
                >
                  <motion.div className={css.overlayTop}>
                    <img 
                        className={css.oImg}

                      src={
                        HomeProjectData.find((card) => card.id === selectedId)
                          .img
                      }
                      alt={
                        HomeProjectData.find((card) => card.id === selectedId)
                          .alt
                      }
                    />
                    
                    <motion.div
                      key="close"
                      className={css.closeIcon}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 0.3 }}
                      exit={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                    >
                    </motion.div>
                  </motion.div>
                  
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
    </>
  );
};

export default Projects;
