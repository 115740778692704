import React, { useState } from "react";
import { Link } from "react-scroll";

import { HomeScrollTabData } from "../../data/HomeScrollTabData";
import {
  ScrollTabSection,
  ScrollTabBox,
  ScrollTabTitle,
  ScrollTabIcon,
} from "./HomeScrollTab.elements";

const HomeScrollTab = () => {
  const [scrollActive, setScrollActive] = useState(false);

  const scrollActivated = () => {
    if (window.scrollY >= 600) setScrollActive(true);
    else setScrollActive(false);
  };

  window.addEventListener("scroll", scrollActivated);
  return (
    <>
      <ScrollTabSection className={scrollActive ? "ScrollTabActive " : null}>
        <ScrollTabBox>
          {HomeScrollTabData.map((sData, index) => (
            <Link activeClass="active" to={sData.id} spy={true} key={index}>
              <ScrollTabTitle>
                <ScrollTabIcon>{sData.scrollIcon}</ScrollTabIcon>
                {sData.scrollTitle}
              </ScrollTabTitle>
            </Link>
          ))}
        </ScrollTabBox>
      </ScrollTabSection>
    </>
  );
};

export default HomeScrollTab;
