import styled from "styled-components";

export const ContactSection = styled.section`
`
export const ContactRow = styled.div`
display:flex;
justify-content:center;
align-items:center;
`
export const ContactCol = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
background-color:var(--Second_Color);
border-radius:2rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
padding:5rem;
& input{
    margin:1.6rem 0rem;
    width:36rem;
    /* background-color:var(--First_Color); */
    height: 4rem;
    border:none;
    outline:none;
    border-radius:50rem;
    padding:1rem 2rem;;
  /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.095); */

    border-bottom:.2rem solid white;
}
`