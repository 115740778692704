import React from "react";
import { Link } from "react-router-dom";
import {
  ComProfileSection,
  ComProfileRow,
  ComProfileCol,
  ComProfileTitle,
  ComProfilePara,
  ComProfileHeading,
  ComProfileSkillPara,
} from "./CommonProfile.elements";
const CommonProfile = () => {
  return (
    <>
      <ComProfileSection id="ComProfiles">
        {/* {
            ProfileData.map((pData, index)=>(

              <ComProfileRow>
              <ComProfileCol>
                <ComProfileTitle>{pData.pTitle}</ComProfileTitle>
                {data ? <ComProfilePara>
                  <ComProfileHeading>{pData.pSpan} </ComProfileHeading>
                  {pData.pPara}
                </ComProfilePara>
                :
                <ComProfilePara>
                  <ComProfileHeading>{pData.pSpans}</ComProfileHeading>
                  {pData.pParas}
                </ComProfilePara>
              }
              </ComProfileCol>
            </ComProfileRow>
            ))
          } */}
        <ComProfileRow>
          <ComProfileCol>
            <ComProfileTitle>Info</ComProfileTitle>
            <ComProfilePara>
              <ComProfileHeading>Email ID :</ComProfileHeading>
              shubhamsaini1379@gmail.com
            </ComProfilePara>
          </ComProfileCol>
        </ComProfileRow>
        <ComProfileRow>
          <ComProfileCol>
            <ComProfileTitle>Profile</ComProfileTitle>
            <ComProfilePara>
              I started my career in the IT field in 2018, I love this work and
              I am very honest about my work, I have a lot to learn in this
              field right now and I keep learning. I love learning new
              technologies, doing so increases my knowledge, and because of that
              knowledge, I feel confident in myself and I share everything I
              learn with everyone. Because knowledge grows by sharing.
            </ComProfilePara>
          </ComProfileCol>
        </ComProfileRow>
        <ComProfileRow>
          <ComProfileCol>
            <ComProfileTitle>Education</ComProfileTitle>
            <ComProfilePara>
              12th, Haryana Open School ( Bhiwani )
            </ComProfilePara>
          </ComProfileCol>
        </ComProfileRow>
        <ComProfileRow>
          <ComProfileCol>
            <ComProfileTitle>Courses</ComProfileTitle>
            <ComProfilePara>
              <ComProfileHeading>Hartron, HIIT</ComProfileHeading>Diploma in Web
              Technologies
            </ComProfilePara>
            <ComProfilePara>
              <ComProfileHeading>Udemy</ComProfileHeading>Node.js API
              Masterclass With Express & MongoDB
            </ComProfilePara>
          </ComProfileCol>
        </ComProfileRow>
        <ComProfileRow>
          <ComProfileCol>
            <ComProfileTitle>Work Experience</ComProfileTitle>
            <ComProfilePara>
              <ComProfileHeading>Zoom Infotech Pvt. Ltd.</ComProfileHeading>
              2.5 Years of Work Experience as a Web Designer and Developer.
            </ComProfilePara>
          </ComProfileCol>
        </ComProfileRow>
        <ComProfileRow>
          <ComProfileCol>
            <ComProfileTitle>Skills</ComProfileTitle>
            <ComProfileSkillPara>HTML</ComProfileSkillPara>
            <ComProfileSkillPara>CSS</ComProfileSkillPara>
            <ComProfileSkillPara>JavaScript</ComProfileSkillPara>
            <ComProfileSkillPara>Node Js</ComProfileSkillPara>
            <ComProfileSkillPara>React Js</ComProfileSkillPara>
            <ComProfileSkillPara>Wordpress</ComProfileSkillPara>
            <ComProfileSkillPara>PHP</ComProfileSkillPara>
            <ComProfileSkillPara>Photoshop</ComProfileSkillPara>
            <ComProfileSkillPara>Illustrator</ComProfileSkillPara>
          </ComProfileCol>
        </ComProfileRow>
        <ComProfileRow>
          <ComProfileCol className="m_Bottom">
            <ComProfileTitle>Projects</ComProfileTitle>
            <ComProfilePara>
              You can see My Projects Please{" "}
              <Link to="/projects">Click Here</Link>
            </ComProfilePara>
          </ComProfileCol>
        </ComProfileRow>
      </ComProfileSection>
    </>
  );
};

export default CommonProfile;
